import { ZDTCustomer, ZDTNewsletter } from '@zalora/doraemon-ts';
import { SESSION_STORAGE_KEYS } from 'constants/session-storage';
import { InteractiveJourneyStepID } from 'types/interactive-journey';
import { getEnvConfiguration } from 'utils/env-configuration';
import { isUserSubscribedNewsLetter } from 'utils/user/news-letter/isUserSubscribedNewsLetter';
import { isObject } from 'utils/validation';
import { getPlatformSource } from './get-platform-source';
import { getUserTraits, shouldAllowTracking } from './methods';

export const SEGMENT_LOGIN_METHOD_COOKIE_KEY = 'segment-login-method';

type LoginMethod = 'signIn' | 'signUp';

const LoginTrackingEventName: Record<LoginMethod, string> = {
  signIn: 'Signed In',
  signUp: 'Signed Up',
};

export const identify = (userId: string, traits?: unknown) => {
  if (!userId || !shouldAllowTracking()) {
    return;
  }

  window.analytics.identify(userId, isObject(traits) ? traits : {});
};

export const identifyUser = ({
  user,
  newsLetter,
}: {
  user: ZDTCustomer.Customer;
  newsLetter: Nullishable<ZDTNewsletter.Details[]>;
}) => {
  if (!user.Zuid) {
    return;
  }

  const venture = getEnvConfiguration('CC');
  const emailSubscribed = isUserSubscribedNewsLetter(newsLetter);

  identify(
    user.Zuid,
    getUserTraits({
      user,
      hasMembership: !!user.IsVipMember,
      venture,
      emailSubscribed,
    }),
  );
};

/**
 * This is supporting for during transition phase, where we do login in alita.
 * After Alita logins successful on server PHP, Alita will set a login method (segment-login-method) to cookie
 * So, from Lotus, we will check if segment-login-method is available in cookie, then we have to call identify
 */
export const identifyAfterLogin = (
  loginMethod: string | undefined,
  user: ZDTCustomer.Customer,
  newsLetter: Nullishable<ZDTNewsletter.Details[]>,
) => {
  if (!user.Zuid || !loginMethod || !shouldAllowTracking()) {
    return;
  }

  identifyUser({ user, newsLetter });

  window.analytics.track(
    LoginTrackingEventName[loginMethod as LoginMethod],
    generatePayloadForInteractiveJourneyTracking({
      user_id: user.Zuid,
      email: user.Email,
      platform_source: getPlatformSource(),
    }),
  );
};

export const generatePayloadForInteractiveJourneyTracking = <T extends Record<string, unknown>>(
  payload: T,
): T | (T & { list_id?: string; nc_interactive_variant?: string }) => {
  const key = SESSION_STORAGE_KEYS.INTERACTIVE_JOURNEY_ORIGIN_STEP;
  const origin = sessionStorage.getItem(key);

  const isOriginInteractiveJourney = origin === InteractiveJourneyStepID.SIGN_UP;
  const loginMethod = sessionStorage.getItem(SESSION_STORAGE_KEYS.INTERACTIVE_JOURNEY_LOGIN_METHOD);
  const isSignUp = loginMethod === 'signUp';
  const isInteractiveJourneySignUp = isOriginInteractiveJourney && isSignUp;
  const interactiveJourneyVariant = isInteractiveJourneySignUp ? 'nc_interactive_journey' : 'false';

  if (isSignUp) {
    Object.assign(payload, { nc_interactive_variant: interactiveJourneyVariant });
  }

  if (!origin) {
    return payload;
  }

  if (isOriginInteractiveJourney) {
    Object.assign(payload, { list_id: 'interactive_journey' });
  }

  sessionStorage.removeItem(key);

  return payload;
};
