import { ModalStackItem, ToastVariant } from '@zalora/zui';
import dynamic from 'next/dynamic';
import { createStore } from 'zustand';
import { mutative } from 'zustand-mutative';
import { isMobileScreenSize } from 'utils/screen-size';
import { getDurationTimeOfToast } from 'utils/toast';
import { ToastStackItem, UiStore } from './types';

const Toast = dynamic(() => import('components/Toast'), { ssr: false });

const TOAST_STACK_MAX_ITEMS = 5;

type PreloadedState = Pick<
  UiStore,
  | 'activeSegment'
  | 'segments'
  | 'onPdvInteractedListener'
  | 'topBrands'
  | 'topSearches'
  | 'footerContent'
>;

export const createUiStore = (preloadedState: PreloadedState) => {
  return createStore(
    mutative<UiStore>((set, get) => ({
      // Observables
      activeModalIdx: -1,
      activeSegment: preloadedState.activeSegment || '',
      isModalOpen: false,
      modalStack: [] as ModalStackItem[],
      segments: preloadedState.segments || [],
      toastStack: [] as ToastStackItem[],
      onPdvInteractedListener: [],
      isOpenAppSmartBanner: true,
      searchQuery: '',
      topBrands: preloadedState.topBrands || [],
      topSearches: preloadedState.topSearches || [],
      showInteractiveJourneyConfetti: false,
      footerContent: preloadedState.footerContent || {},

      // Actions
      addToast: (toast) => {
        const toastPosition = isMobileScreenSize()
          ? { positionX: 'center', positionY: 'bottom' }
          : { positionX: 'left', positionY: 'bottom' };

        set((state) => {
          state.toastStack = [
            {
              className: toast.positionY === 'top' ? 'mt-10' : 'pb-16',
              positionX: toast.positionX || toastPosition.positionX,
              positionY: toast.positionY || toastPosition.positionY,
              toasts: [
                <Toast
                  variant={(toast.variant as ToastVariant) || 'success'}
                  message={toast.message || ''}
                  duration={getDurationTimeOfToast(toast.message || '')}
                  key={`toast-${Date.now()}`}
                />,
              ],
            },
            ...state.toastStack.slice(TOAST_STACK_MAX_ITEMS),
          ];
        });
      },

      setToastStack: (toastStack) => {
        set((state) => {
          state.toastStack = toastStack;
        });
      },

      showModal: (modalStackItem) => {
        // Do not allow duplicate modals
        const isExisting = get().modalStack.some(({ key }) => key === modalStackItem.key);

        if (isExisting) {
          if (process.env.CI) {
            // eslint-disable-next-line no-console
            console.warn(
              `Warning: (CI only) Modal with key "${modalStackItem.key}" already exists in the stack.`,
            );
          }

          return;
        }

        set((state) => {
          state.modalStack.push(modalStackItem);
        });
      },

      _setActiveModalIdx: (idx) => {
        set((state) => {
          state.activeModalIdx = idx;
        });
      },

      _setModalStack: (modalStackItems) => {
        set((state) => {
          state.modalStack = modalStackItems;
        });
      },

      closeAppSmartBanner: () => {
        set((state) => {
          state.isOpenAppSmartBanner = false;
        });
      },

      setDeeplink: (deeplink) => {
        set((state) => {
          state.deeplink = deeplink;
        });
      },

      setActiveSegment: (segment) => {
        set((state) => {
          state.activeSegment = segment;
        });
      },

      // Actions
      addPdvInteractedListener: (listener) => {
        set((state) => {
          state.onPdvInteractedListener.push(listener ? listener : {});
        });
      },

      removePdvInteractedListener: (listener) => {
        set((state) => {
          state.onPdvInteractedListener = state.onPdvInteractedListener.filter(
            (l) => l !== listener && JSON.stringify(l) !== JSON.stringify(listener),
          );
        });
      },

      setSegments: (segments) => {
        set((state) => {
          state.segments = segments;
        });
      },

      setSearchQuery: (searchQuery) => {
        set((state) => {
          state.searchQuery = searchQuery;
        });
      },

      /**
       * Show confetti animation as a side effect when user
       * subscribes to updates from Interactive Journey
       */
      setShowInteractiveJourneyConfetti: (show) => {
        set((state) => {
          if (state.showInteractiveJourneyConfetti === show) {
            return;
          }

          state.showInteractiveJourneyConfetti = show;
        });
      },
    })),
  );
};
