import { useEffect, useState } from 'react';
import { getLoginMethod, removeLoginMethod } from 'utils/authentication';
import isServer from 'utils/is-server';
import { identifyAfterLogin } from 'utils/segment-analytic';
import useNewsLetter from './api/useNewsLetter';
import useUser from './api/useUser';
import { useInvokeCallbackOnceWhen } from './useInvokeCallbackOnceWhen';

const useAfterAuthentication = () => {
  const [loginMethod, setLoginMethod] = useState(() => (isServer() ? undefined : getLoginMethod()));
  const { data: user } = useUser();
  const { data: newsLetter, isLoading: isLoadingNewsLetter } = useNewsLetter();

  useEffect(() => {
    if (!loginMethod) {
      return;
    }

    import('utils/interactive-journey')
      .then(({ persistAuthenticationInfo }) => {
        persistAuthenticationInfo(loginMethod);
      })
      .catch(() => {
        /* noop */
      });
  }, [loginMethod]);

  // When app mounted and have user info, do some init with user data
  useInvokeCallbackOnceWhen(
    () => {
      if (user && loginMethod) {
        identifyAfterLogin(loginMethod, user, newsLetter);

        // Remove login method after identification

        removeLoginMethod();
        setLoginMethod(undefined);
      }
    },
    !!loginMethod && !!user && !isLoadingNewsLetter,
  );
};

export default useAfterAuthentication;
