import * as DeviceId from 'utils/device-id';
import { getEnvConfiguration } from 'utils/env-configuration';
import { getPageType } from 'utils/page-type';
import { isObject } from 'utils/validation';
import { getPlatformSource } from './get-platform-source';
import { getOptions, shouldAllowTracking } from './index';

export const track = (
  eventName: string,
  properties?: unknown,
  trackingOptions?: {
    shouldIncludeSourcePageType?: boolean;
  },
) => {
  if (!shouldAllowTracking()) {
    return;
  }

  const { shouldIncludeSourcePageType } = trackingOptions || {};
  const platformSource = getPlatformSource();
  const venture = getEnvConfiguration('CC')?.toUpperCase();
  const sourcePageType = getPageType() || '';
  const deviceId = getDeviceId();
  const options = getOptions({
    Optimizely: { userId: deviceId },
  });

  window.analytics.track(
    eventName,
    {
      platform_source: platformSource,
      venture_code: venture,
      ...(shouldIncludeSourcePageType ? { source_page_type: sourcePageType } : {}),
      ...(isObject(properties) ? properties : {}),
    },
    options,
  );
};

const getDeviceId = () => {
  let deviceId = DeviceId.getDeviceId();

  if (!deviceId) {
    deviceId = DeviceId.generateDeviceId();
  }

  return deviceId;
};
